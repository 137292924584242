<template>
  <v-container v-if="!lodash.isEmpty(evento)">
    <v-row>
      <v-col cols="12">
        <PageToolbar
          :title="$tc('eventos.baixa')"
          icon="done_all"
          voltar
          dark
        />
      </v-col>

      <v-col cols="12">
        <v-card :loading="loading" :disabled="loading">
          <!-- Titulo -->
          <v-card-title class="headline">{{ evento.titulo }}</v-card-title>
          <v-divider></v-divider>
          <!-- ------------------------------------------------- -->

          <v-card-title class="subtitle-1">
            <div>Notas</div>
          </v-card-title>

          <v-card-text>
            <v-card outlined v-for="nota in notas" :key="nota.id">
              <v-card-title class="d-flex align-start justify-space-between">
                <div class="subtitle-1" style="line-height: 1.1;">
                  {{ buscaUsuario(nota.user_id) }}
                </div>
                <div style="font-size: 10px; line-height: 1.1;">
                  {{ nota.created_at | moment("DD/MM/YYYY") }} as
                  {{ nota.created_at | moment("HH:mm:ss") }}
                </div>
              </v-card-title>
              <v-card-text class="body-2">{{ nota.nota }}</v-card-text>
            </v-card>
          </v-card-text>
          <v-card-text>
            <v-card
              flat
              color="lightgray"
              class="pa-4"
              :loading="loadingNovaNota"
              :disabled="loadingNovaNota"
            >
              <v-textarea
                v-model="nota"
                :placeholder="$tc('msg.escrevanota')"
                :rows="1"
                class="pa-0 ma-0"
                row-height="25"
                hide-details
                auto-grow
              ></v-textarea>
              <v-btn
                v-if="notaTextFocus"
                @click="salvarNota"
                :disabled="!notaValid"
                class="mt-2 pa-0"
                color="anchors"
                text
                dense
                small
                >{{ $tc("botoes.salvar") }}</v-btn
              >
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-btn
      @click="fecharEvento"
      fixed
      right
      bottom
      fab
      dark
      color="buttons"
      :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
    >
      <v-icon>done_all</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { getEvento, putEvento } from "@/services/api/eventos.api.js";
import {
  getEventosNotasPorEvento,
  postEventosNota,
} from "@/services/api/eventos-notas.api.js";

import { mapState, mapActions } from "vuex";

export default {
  name: "EventoEditar",
  props: ["eventoId"],
  data: () => ({
    evento: {
      descricao: "",
    },
    loading: false,
    loadingNovaNota: false,
    notaTextFocus: false,
    notas: [],
    nota: "",
  }),
  computed: {
    ...mapState("Usuario", {
      isAdmin: (state) => state.usuario.is_admin,
      user_id: (state) => state.usuario.id,
      usuarios: (state) => state.usuarios,
    }),
    nome() {
      let splitName = this.$store.state.Usuario.usuario.name.split(/ /);
      let firstName = splitName[0] ? splitName[0] : "";
      let lastName = splitName[1] ? splitName[1] : "";
      let name = `${firstName} ${lastName}`;
      return name;
    },
    evento_id() {
      return this.$route.params.eventoId;
    },
    notaValid() {
      let valid = false;
      if (this.nota.length > 4) {
        valid = true;
      }
      return valid;
    },
  },
  watch: {
    nota() {
      if (this.nota.length > 4) {
        this.notaTextFocus = true;
      } else {
        this.notaTextFocus = false;
      }
    },
  },
  methods: {
    buscaUsuario(id) {
      let user = this.usuarios.find((item) => item.id === id);
      if (user) return user.name;
    },
    getEvento() {
      this.loading = true;
      getEvento(this.evento_id)
        .then((response) => {
          this.evento = response;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fecharEvento() {
      this.loading = true;
      this.evento.status = 3;
      this.evento.concluido_em = this.$moment().format("YYYY-MM-DD HH:mm:ss");
      putEvento(this.evento_id, this.evento)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("eventos.baixasucesso"));
            this.$router.go(-1);
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getEventosNotas() {
      getEventosNotasPorEvento(`?evento_id=${this.evento_id}`)
        .then((response) => {
          this.notas = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    salvarNota() {
      this.loadingNovaNota = true;
      let notaObj = {};
      notaObj.evento_id = this.evento_id;
      notaObj.user_id = this.user_id;
      notaObj.nota = this.nota;
      postEventosNota(notaObj)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("msg.criadosucesso"));
            this.getEventosNotas();
            this.nota = "";
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingNovaNota = false;
        });
    },
  },
  mounted() {
    this.getEvento();
    this.getEventosNotas();
  },
  created() {},
};
</script>

<style lang="scss" scoped>
::v-deep .headline {
  input {
    font-size: 1.4rem !important;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: normal !important;
  }
}
</style>
